<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Labot/skatīt professionālās meistarības pilnveidi"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-col cols="12" class="text-center" v-if="!users && !options">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Professionālās pilnveides kurss tika veiksmīgi labots!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <p v-for="(e, index) in errors" :key="index"> {{ e[0] }} </p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-form @submit="onSubmit" enctype="multipart/form-data">
            <b-form-group
                id="input-group-23"
                label="Darbinieka vārds, uzvārds:"
                label-for="input-23"
            >
              <b-form-input
                  id="input-23"
                  list="user-list"
                  v-model="$v.form.userName.$model"
                  aria-describedby="input-23-live-feedback"
                  :state="validatePositions($v.form.user_id)"
                  @input="changeName(form.userName)"
              ></b-form-input>

              <b-form-invalid-feedback
                  id="input-1-live-feedback"
              >Nepareizi ievadīts darbinieka vārds un uzvārds</b-form-invalid-feedback>
              <div>
              </div>
              <datalist id="user-list">
                <option>Nav</option>
                <option v-for="user in users" :id="user.id" :selected="form.user_id === user.id">{{ user.name }} {{ user.surname }}</option>
              </datalist>
            </b-form-group>
            <b-form-group
                id="input-group-5"
                label="Rīkojuma datums:"
                label-for="input-5"
            >

              <b-form-input id="input-5"
                            type="date"
                            v-model="$v.form.order_date.$model"
                            aria-describedby="input-5-live-feedback"
                            :state="validatePositions($v.form.order_date)"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-5-live-feedback"
              >Nepareizs rīkojuma datums</b-form-invalid-feedback>

            </b-form-group>

            <b-form-group
                id="input-group-5"
                label="Trīs gadu termiņa sākuma datums:"
                label-for="input-5"
            >

              <b-form-input id="input-5"
                            type="date"
                            v-model="$v.form.start_date.$model"
                            aria-describedby="input-5-live-feedback"
                            :state="validatePositions($v.form.start_date)"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-5-live-feedback"
              >Nepareizs trīs gadu termiņa sākuma datums</b-form-invalid-feedback>

            </b-form-group>


            <b-form-group
                id="input-group-1"
                label="Rīkojuma numurs:"
                label-for="input-1"
            >
              <b-form-input id="input-1"
                            v-model="$v.form.order_number.$model"
                            aria-describedby="input-1-live-feedback"
                            :state="validatePositions($v.form.order_number)"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-1"
                label="Stundu skaits:"
                label-for="input-1"
            >
              <b-form-input
                  id="filter-input"
                  v-model="$v.form.hours.$model"
                  :debounce="300"
                  type="number"
                  :state="validatePositions($v.form.hours)"
              ></b-form-input>
            </b-form-group>


            <b-form-group
                id="input-group-1"
                label="Komentārs: "
                label-for="input-1"
            >
              <b-form-textarea
                  v-model="$v.form.comment.$model"
                  :state="validatePositions($v.form.comment)"
              >
              </b-form-textarea>
            </b-form-group>

            <b-form-group
                id="input-group-15"
                label="Pielikums:"
                label-for="input-15"
            >

              <b-form-file
                  v-model="form.attachment"
                  placeholder="Izvēlieties failu vai ievelciet to šeit..."
                  drop-placeholder="Ievelciet failu šeit..."
                  multiple
                  @change="onFileChange"
              ></b-form-file>

            </b-form-group>

            <div v-if="form.attachments.length > 0">
              <h3>Pielikumi</h3>
              Faili tiks dzēsti tikai tad, kad izmaiņas tiks saglabātas
              <div v-for="(file, index) in form.attachments" :key="index">
                <a :href="media+'/'+file.path" :style="{color: file.delete ? 'gray' : 'blue'  }">{{ file.name }}</a>
                <span @click="file.delete = !file.delete" style="cursor: pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
              </span>
              </div>
            </div>
            <b-button type="submit" variant="success">Saglabāt</b-button>
            <b-button variant="warning" class="ml-1" :to="{ name: 'CourseList' }">Atpakaļ</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import jsonToFormData from '@ajoelp/json-to-formdata';
import {mapActions, mapGetters} from "vuex";
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: "Create",
  data() {
    return {
      users: [],
      selectedUser: null,
      media: process.env.VUE_APP_MEDIA_URL,
      form: {
        user_id: null,
        order_date: null,
        start_date: null,
        order_number: null,
        hours: null,
        userName: null,
        comment: '',
        attachments: [],
        attachment: null,
        files: []
      },
      created: null,
      dismissSecs: 5,
      dismissCountDown: 0,
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      user_id: {
        required,
      },
      userName: {
        required
      },
      order_date: {
        required,
      },
      start_date: {
      },
      order_number: {
        required,
      },
      hours: {
        required,
      },
      comment: {
      },
      files: {
      }
    },
  },
  mounted () {
    this.getFormData()

    axios.get('/users').then(response => {
      this.users = response.data.data
    })
  },
  computed: {
    ...mapGetters ({
      options: "Options/options",
    })
  },
  methods: {
    ...mapActions ({
      fetchOptions: "Options/fetchOptions"
    }),
    onSubmit(event) {
      event.preventDefault()

      this.errors = [];

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.errors.push('Nav aizpildīti visi lauki')
        window.scrollTo(0, 0);
        return;
      }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      let formData = new FormData()
      for (let [key, value] of Object.entries(this.form)) {
        if (key === 'files') {
          for(var index = 0; index < this.form.files.length; index++) {
            formData.append('files[]', this.form.files[index])
          }
        } else if (key === 'attachments') {
          formData.append(key, JSON.stringify(value))
        } else if (value === null) {
          continue
        } else {
          formData.append(key, value)
        }
      }

      axios.post('/courses/'+this.$route.params.id, jsonToFormData({_method: 'PATCH'}, {}, formData, config)).then(response => {
        this.created = 'success';
        this.getFormData();
      }).catch(e => {
        this.created = 'error';
        this.errors = e.response.data.errors
      })

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      this.dismissCountDown = 5;
    },
    changeName(value) {
      if(value !== 'Nav') {
        let user = (this.users).find((e) => e.name + ' ' + e.surname === value)
        if(user !== undefined) {
          this.form.user_id = user.id
        }
        console.log(value)

      } else {
        this.form.user_id = null
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    onFileChange(event) {
      this.form.files = event.target.files
    },
    validateState(name) {
      // console.log(name)

      const {$dirty, $error} = this.$v.form[name];

      return $dirty ? !$error : null;
    },
    validatePositions(object) {
      // console.log(object)

      const {$dirty, $error} = object;

      return $dirty ? !$error : null;
    },
    getFormData() {
      axios.get('/courses/'+this.$route.params.id).then(response => {
        let data = response.data.data
        this.form.order_date = data.order_date
        this.form.start_date = data.start_date
        this.form.order_number = data.order_number
        this.form.hours = data.hours
        this.form.comment = data.comment || ''
        this.form.attachment = null
        this.form.user_id = data.user_id
        this.form.userName = data.name + ' ' + data.surname
        let files = JSON.parse(data.files) || []
        this.form.attachments = files.map(file => {
          file.delete = false
          return file
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>